import { PAGES } from '@js/navigations'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('sessionUser', [
      'role'
    ]),
    page() {
      return Object.values(PAGES).find(nav => nav.path === this.$route.path)
    }
  },
  created() {
    if (this.page) {
      this.$watch('role', {
        immediate: true,
        handler() {
          // redirect users to pack & dispatch page if not authorized
          if (this.role) {
            const isAuthorized = !this.page.authorizedRoles || this.page.authorizedRoles.includes(this.role)
            if (!isAuthorized) {
              this.$router.replace({ name: 'pack-and-dispatch' })
            }
          }
        }
      })
    }
  }
}
