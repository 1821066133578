<template>
  <div class="users-page">
    <div class="container">
      <breadcrumb :navs="breadcrumbNavs"/>
      <div class="page-header columns is-mobile">
        <div class="column">
          <div class="page-header-title">
            {{ $t('navbar.navs.admin.usersManagement') }}
          </div>
        </div>
        <div class="column is-9 has-text-right">
          <users-role-filter
            :users="users"
            :value.sync="roleFilter"/>
          <users-hub-filter :value.sync="hubFilter"/>
          <search-field @search="search"/>
          <b-button
            type="is-primary"
            @click="showUserFormModal">
            {{ $t('users.actions.createAccount') }}
          </b-button>
        </div>
      </div>
      <users-table
        :users="usersToDisplay"
        @edit="onEditUser"/>
    </div>
    <b-modal
      :active.sync="userFormModalActive"
      animation
      hasModalCard
      @close="onCloseUserFormModal">
      <user-form-handler
        ref="userFormHandler"
        formComponentName="user-form"
        formComponentUserPropName="user"
        :selectedUser="selectedUser"
        createUserListenerName="user/createUser"
        updateUserListenerName="user/updateUser"/>
    </b-modal>
    <b-loading :active="loadingOverlayVisible"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isSystemAccount } from '@js/utils'
import pageAuthorizationMixin from '@/mixins/page-authorization-mixin'

const Breadcrumb = () => import('@components/Breadcrumb')
const SearchField = () => import('@components/SearchField')
const UserFormHandler = () => import('@components/UserFormHandler')
const UsersTable = () => import('@components/UsersTable')
const UsersHubFilter = () => import('@components/UsersHubFilter')
const UsersRoleFilter = () => import('@components/UsersRoleFilter')

export default {
  name: 'users-page',
  components: {
    Breadcrumb,
    SearchField,
    UserFormHandler,
    UsersTable,
    UsersHubFilter,
    UsersRoleFilter
  },
  mixins: [pageAuthorizationMixin],
  data() {
    return {
      breadcrumbNavs: [
        {
          label: this.$t('common.home'),
          path: { name: 'home' }
        },
        {
          label: this.$t('navbar.navs.admin.usersManagement')
        }
      ],
      roleFilter: [],
      hubFilter: [],
      searchedUsers: [],
      lastSearchTerm: null,
      userFormModalActive: false,
      selectedUser: null
    }
  },
  computed: {
    ...mapGetters('api', [
      'getLoading'
    ]),
    ...mapGetters('hub', [
      'hubs'
    ]),
    ...mapGetters('user', [
      'users',
      'nonCourierTypes'
    ]),
    loadingOverlayVisible() {
      return this.getLoading(
        'user/createUser',
        'user/getUsers',
        'user/updateUser'
      )
    },
    usersToDisplay() {
      if (this.lastSearchTerm) {
        return this.searchedUsers
      }

      let users = this.users
      if (this.roleFilter.length > 0 && this.roleFilter.length < this.nonCourierTypes.length) {
        users = users.filter(user => this.roleFilter.includes(user.role))
      }
      if (this.hubFilter.length > 0 && this.hubFilter.length < this.hubs.length) {
        users = users.filter(user => {
          return this.hubFilter.some(hubId => user.authorizedHubIds.includes(hubId))
        })
      }
      return users
    }
  },
  created() {
    this.resetHubStore()
    this.resetUserStore()

    this.getHubsRequest()
    // component in the router cannot immediately add event listener to the event bus
    this.newUserRequest()
    this.$nextTick(() => {
      this.$eventBus.$on('user/newUserSuccess', () => {
        this.getUsersRequest()
      })
    })
  },
  methods: {
    ...mapActions('hub', [
      'resetHubStore',
      'getHubsRequest'
    ]),
    ...mapActions('user', [
      'resetUserStore',
      'getUsersRequest',
      'newUserRequest'
    ]),
    search(searchTerm) {
      this.lastSearchTerm = searchTerm
      if (!searchTerm) {
        this.searchedUsers = []
        return
      }

      const search = RegExp(this.searchTerm, 'i')
      this.searchedUsers = this.users.filter(user => {
        if (search.test(user.name)) {
          return true
        } else if (search.test(user.email)) {
          return true
        } else if (!isSystemAccount(user) && search.test(user.mobileNumber)) {
          return true
        }
        return false
      })
    },
    showUserFormModal() {
      this.userFormModalActive = true
    },
    onEditUser(user) {
      this.selectedUser = user
      this.showUserFormModal()
    },
    onCloseUserFormModal() {
      this.selectedUser = null
      this.$refs.userFormHandler.closeNotification()
    }
  }
}
</script>

<style lang="scss" scoped>
.users-page {
  .container {
    margin-top: $space-l;

    .page-header {
      align-items: flex-end;

      .page-header-title {
        white-space: nowrap;
        @extend %display_medium;
      }
    }

    .columns > .column:last-child {
      > :not(:first-child) {
        margin-left: $space-xs;
      }

      .button {
        @extend %body;
      }
    }

    .search-field {
      max-width: 160px;
    }
  }
}
</style>
