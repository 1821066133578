import i18n from '@/i18n'

const ROLES = {
  ADMIN: 'admin',
  COORDINATOR: 'coordinator',
  HUB_MANAGER: 'hub_manager',
  CUSTOMER_CARE: 'customer_specialist',
  PACKER: 'packer'
}
const ADMIN_ROLES = [
  ROLES.ADMIN,
  ROLES.COORDINATOR,
  ROLES.HUB_MANAGER
]

export const PAGES = {
  HOME: {
    path: '/',
    authorizedRoles: ADMIN_ROLES
  },
  ASSIGNMENT: {
    label: i18n.t('navbar.navs.main.assignment'),
    path: '/assignment',
    authorizedRoles: ADMIN_ROLES
  },
  PRINT: {
    label: i18n.t('navbar.navs.main.print'),
    path: '/print'
  },
  PACK_AND_DISPATCH: {
    label: i18n.t('navbar.navs.main.packAndDispatch'),
    path: '/pack-and-dispatch'
  },
  TASKS: {
    label: i18n.t('navbar.navs.main.tasks'),
    path: '/orders'
  },
  ROSTER: {
    label: i18n.t('navbar.navs.fulfilment.couriersManagement'),
    icon: require('@images/icons/user-with-cap.svg'),
    path: '/couriers',
    authorizedRoles: ADMIN_ROLES
  },
  BILLING: {
    label: i18n.t('navbar.navs.fulfilment.courierBilling'),
    icon: require('@images/icons/receipt.svg'),
    path: '/billing',
    authorizedRoles: ADMIN_ROLES
  },
  USERS: {
    label: i18n.t('navbar.navs.admin.usersManagement'),
    icon: require('@images/icons/multiple-users.svg'),
    path: '/users',
    authorizedRoles: [ROLES.ADMIN]
  }
}

export function getFilteredNavigations(role, navigations) {
  if (!role) return []

  return navigations.filter(item => {
    if (item.authorizedRoles) {
      return item.authorizedRoles.includes(role)
    }
    return true
  })
}
